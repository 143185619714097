/*
 * Copyright 2022 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$behavior-prompt-bg: #000;
$behavior-prompt-text-color: #fff;
$behavior-prompt-border: #999;
$behavior-prompt-z-index: 100;

.behavior-prompt {
  position: absolute;
  display: block;
  text-align: center;
  font-family: sans-serif;
  background-color: $behavior-prompt-bg;
  color: $behavior-prompt-text-color;
  z-index: $behavior-prompt-z-index;
  padding: 10px 20px;
  border-radius: 8px 2px 2px 8px;

  &.arrow-right {
    &:after {
      position: absolute;
      content: "";

      // Because of the 45 deg rotation, the diagnol of the
      // square must equal the height of the parent rectangle.
      // The part we care about is the right half, which can be
      // thought of as 2 isoceles right triangles stacked back
      // to back. So solve for the hypotenuse where the 2 equal
      // sides are half the height of the parent plus a few
      // pixels to account the shortening by border-radius.
      // The resulting hypotenuse should be the width and height
      // values below (except that I liberally rounded these):
      width: 30px;
      height: 30px;

      // found empirically; too lazy to calculate. good enough.
      right: -14.3px;
      top: 4.5px;

      background: $behavior-prompt-bg;
      border-radius: 4px;
      transform: rotate(45deg);
    }
  }

  &.small {
    height: 40px;
    max-width: 300px;
  }
}
