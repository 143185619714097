/*
 * Copyright 2022 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$icon-color: #647984;
$border-color: #ccc;

.search-box-wrapper {
  @include icon-before($type: $fa-var-search, $size: 14px, $top: 5px, $color: $icon-color) ;

  display:  inline-block;
  position: relative;

  &:before {
    position:  absolute;
    left:      10px;
    color:     $icon-color;
    top:       8px;
    font-size: 14px;
  }
}

.search-box-input {
  height:             40px;
  line-height:        40px;
  padding:            0 10px 0 40px;
  margin:             0;
  border:             1px solid $border-color;
  -webkit-appearance: none;
  box-shadow:         none;
  border-radius:      3px;
  background:         $white-opaque;

  &::placeholder {
    color:     $icon-color;
    font-size: 12px;
    opacity:   0.8;
  }
}
